import * as React from 'react'
import Layout from '../components/layout'
import {
  privacyMain
} from '../lib/privacy.module.css'

const privacyPolicy = () => {
    return (
      <Layout pageTitle="Privacy Policy">
        <div className={privacyMain}>
          <h1>Byron &amp; Byron Ltd<br />Privacy Policy</h1> 
          <p>This Privacy Policy sets out how we collect and use your personal information and what your individual data protection and privacy rights are.</p>
          <p>This Privacy Policy was last updated on 1st January 2021.</p>
          <h2>Who is Responsible for Processing Your Personal Data </h2>
          <p>Byron &amp; Byron Ltd. This is the legal entity responsible for how your personal data is collected, stored and processed.</p>
          <h2>Managing Your Personal Data </h2>
          <p>When you share your personal information with us, you have a right to expect that information to be treated with total confidentiality. Therefore, it is our responsibility to manage your personal data that you provide to us with care and in accordance with all data protection legislation and industry best practice.</p>
          <p>Whether you have supplied your personal details online, by phone, by email or in a letter, we will never use them without a lawful reason to do so. We will use your personal data for the purposes for which they were initially requested and as fully explained in this Privacy Policy.</p> 
          <p>It is your responsibility to ensure that your personal data provided to us is accurate and up to date. You can update your personal contact details, including email address and phone number, if you need to update any other personal details, you can do so by ringing us on 020 8344 7979.</p> 
          <h2>Using Your Personal Data for Direct Marketing Purposes</h2>
          <p>Based on your marketing preferences, we use your email and business address to send you details of our latest products and promotions. For further information on how we use your personal data to keep you informed about our products and promotions and how you can change your marketing preferences, see the relevant sections below.</p>
          <p>We hold your personal data for the purposes of direct marketing to you for a period of three years from the date of your last purchase unless you have an outstanding credit balance or have updated your marketing preferences in which case you will continue to receive marketing information unless you tell us otherwise.</p>
          <h3>Obtaining Your Consent</h3>
          <p>We will obtain your consent to send you details of our latest products and promotions via Email Marketing and Telemarketing in a number of ways. We will obtain your consent when you register your details with us, open a credit account, make a purchase, sign up to our email newsletters, request a catalogue from us. You can change your email and telemarketing preferences at any time - see Changing Your Marketing Preferences.</p>
          <h3>Email Marketing</h3>
          <p>We would like to keep you informed about our latest product offers and promotions using the email address you provide to us. To do this, we will ask you to opt-in to receive marketing emails when you first register an account with us, sign up for our newsletter, for us to use your email address for marketing purposes. You can opt out of email marketing at any time - see changing Your Marketing Preferences.</p> 
          <h3>Telemarketing</h3>
          <p>We will ask your permission to ring you about our latest product offers and promotions when you make a purchase or open a credit account with us. You can opt out of telemarketing at any time - see Changing Your Marketing Preferences.</p> 
          <p>If you have subscribed to the Telephone Preference Service, we will not contact you by phone unless you have explicitly opted in to receiving telemarketing calls from us. </p>
          <h3>Direct Mail</h3>
          <p>We will use your name and address to send you personalised marketing mails in the post. We have a legitimate business interest to send you information about our products and offers in the post as we know that many of our customers like to receive catalogues prior to placing an order with us. However, should you wish to exercise your right to stop personalised postal marketing activity, you can do this at any time - see Changing Your Marketing Preferences.</p> 
          <h3>Social Media</h3>
          <p>We will use your email address to send you online targeted marketing information about our product offers and promotions via social media platforms. To do this, we will share your email address in a secure manner with social media organisations, such as Facebook, who will match our advertising requirements to your social media profile. We have a legitimate business interest to send you product information via social media which we believe is of relevance to you; this is known as Interest-Based Advertising. However, should you wish to exercise your right to stop targeted social media marketing, you can do this at any time - see Changing Your Marketing Preferences.</p> 
          <h3>Third Party Marketing</h3>
          <p>We will not share your personal data with third party companies for the purpose of them marketing their products and services to you.</p> 
          <h3>Changing Your Marketing Preferences</h3>
          <p>You can change your marketing preferences for Email Marketing at any time by:</p> 
          <ul>
            <li>Clicking unsubscribe in the body of the email sent to you;</li>
            <li>Ringing us on 020 8344 7979.</li>
          </ul>
          <p>You can change your marketing preferences for Telemarketing at any time by:</p>
          <ul>
            <li>Send us an email to: <a href="mailto:sales@byronandbyron.com">sales@byronandbyron.com</a></li>
            <li>Ringing us on 020 8344 7979.</li>
          </ul>
          <p>You can exercise your right to object to Direct Mail or Social Media at any time by:</p>
          <ul>
            <li>Send us an email to: <a href="mailto:sales@byronandbyron.com">sales@byronandbyron.com</a></li>
            <li>Ringing us on 020 8344 7979.</li>
          </ul>
          <p>Please allow 48 hours for your changes to be processed for social media. You may still receive direct marketing materials that are already processed before we received your request for up to 2 months.</p>
          <h2>Using Your Personal Data to Improve Your Online Shopping Experience</h2> 
          <p>We want to make sure that we only contact you with online content that you will find relevant and interesting. For this to work effectively, we collect information about your browsing activity using Cookies when you are using our website. These Cookies pass back to us your browsing information using your Internet Protocol ("IP") address as the identifier, which we combine with other anonymised, non-personalised and aggregated data, allowing us to determine what content and product information is most relevant to you when you are shopping online.</p>
          <p>We have a legitimate business interest to use the aggregated and anonymised data obtained via Cookies and other data sources to help us provide you with relevant product marketing and improve your online shopping experience.</p>
          <h2>Using Your Personal Data to Open Your Business Credit Account </h2>
          <p>When you apply for a credit account with us, we will use your business data to make a decision as to whether or not we will accept your application and, if so, the amount of credit we will offer you. Without this data, we are unable to perform the services under this contract i.e. to calculate the amount of credit offered and manage your credit account in accordance with the applicable guidelines and regulation.</p>
          <p>You have a choice to opt out of providing us with your business data for the purpose of making an automated decision at the point of application and select a pro-forma account. By opting out, we will not be able to provide you with a credit account. However, you can still trade with us by opting for a pro-forma account as an alternative and pay with credit or debit card for your order.</p>
          <p>We will hold your personal data for 6 years, plus the current financial year, after the date of your last transaction on your business credit account.</p>
          <h2>Using Your Business Data to Open Your Business Pro-forma Account</h2> 
          <p>When you register to open a business pro-forma account we will hold your data which you have provided to us. When you make a subsequent card or cheque payment to purchase products from us, we will use your personal data and card information to process the order. This involves sending your personal data to our card payment and banking providers to ensure that you have sufficient funds to cover the value of the transaction. Without this data, we are unable to fulfil your order.</p> 
          <p>We will hold your personal data for 6 years, plus the current financial year, after the date of your last transaction on your business pro-forma account.</p> 
          <h3>Processing Card Payments</h3>
          <p>We process all card payments in line with our obligations under the PCI-DSS regulations. Whenever you provide your card details to us either online or over the phone, we will encrypt the payment details before sending to our card payment and banking provider. If you pay over the phone, we will mask any card data that you provide so that this is not visible as well as stopping card data from being recorded in our Call Recordings. This is regularly monitored for quality assurance as part of our PCI-DSS obligations.</p> 
          <p>Any card details provided by customers via paper order forms will be immediately, and securely, destroyed once processed.</p>
          <h3>Using Your Personal Data for Fraud Prevention</h3>
          <p>We carry out fraud checks on new credit applications and credit orders. In order to do this, we use data sourced from Credit Reference Agencies and other external agencies that are involved in fraud detection. If we identify fraud, we will pass data relating to the fraudulent activity such as name, address and email address to these agencies to help protect individuals and other businesses from the threat of fraud in the future.</p> 
          <p>We will hold your personal data for 6 years, plus the current financial year, after the date of your last transaction on your account - credit or pro-forma.</p> 
          <h2>Using Your Personal Data to Operate Your Business Account</h2> 
          <h2>Using Your Personal Data to Improve Our Services</h2>
          <h2>Using Your Personal Data to Process Insurance Claims</h2> 
          <h2>Sharing Your Personal Data with Third Party Processors &amp; Partners</h2>
          <h2>Processing Your Personal Data Outside of the UK</h2>
          <p>We will transfer your personal data outside the UK to prevent fraud, for credit searches and with our outsourced IT partners and contact centres. Please also see Sharing Your Personal Data with Third Party Processors &amp; Partners. In some cases, countries outside the UK may not have such well-developed data protection laws as those in the UK. However, we will put in place measures and safeguards that require your personal data to be kept secure, confidential and to be processed only and strictly in line with the terms of this Privacy Policy and any relevant data protection laws. </p>
          <h2>Your Personal Data Rights</h2>
          <h3>Right of Access</h3>
          <p>You may wish to access a copy of the personal data we hold about you - known as a Subject Access Request. You can do so by ringing, writing to or emailing the team. We will respond to your Subject Access Request as soon as possible and, in any event, within the statutory 30 days. However, in the event that we need more information from you to verify your identity, which we must do to ensure we disclose your personal data to the right person, the 30 day response period will only commence from the time that we have validated your identity.</p>
          <p>Please be aware that for security reasons we do not usually provide details of any bank details that we hold against your account(s). Please speak to our team should you need this additional information.</p>
          <h3>Right of Rectification</h3>
          <p>If you believe we have made an error as to the personal data we hold about you, please speak to one of our customer service advisors on 020 8344 7979 who will be able to process the correction for you.</p>
          <h3>Right of Erasure</h3>
          <p>You have the right to request your personal data to be permanently deleted from our records and systems to avoid any further communication with you. Your request will always be considered in light of the legal bases that we hold, store and process your personal data and the purpose that we collected your data. Where the legal bases permits, we will carry out your instruction without undue delay. Please note, however, that where we have a legal or contractual obligation to hold your personal data, we may not be able to carry out your request but we will explain this fully to you.</p>
          <h3>Right to Restrict Processing</h3> 
          <p>Should you believe that we are processing your personal data in a way that you did not understand or agree to and wish to restrict such processing, please email <a href="mailto:sales@byronandbyron.com">sales@byronandbyron.com</a> with your concern &amp; we will aim to response within 2 working days.</p> 
          <h3>Right to Object to Processing</h3>
          <p>You have the right to object to certain types of processing of your personal data. We will always make it clear at the outset of any new arrangement with you how we are going to process your personal data. Should you wish to object to such processing, particularly concerning Automated Decisioning and Profiling, we will give you the option to opt out on application.</p> 
          <h3>Right to Portability</h3>
          <p>In the event that you wish to move your personal data that we hold on you to another organisation in the form of an excel or csv format, please us.</p>
          <h3>Right to be Informed</h3>
          <p>You have the right to be informed about the collection and use of your personal data. This is commonly known as a 'privacy statement' or 'privacy policy'. We will provide you with the information about how we collect and use your data in various means, such as by 'just in time' notice provided to you at the time of collecting your personal data and via this Privacy Policy. Our Privacy Policy is regularly reviewed in line with our business processes. Any changes to this Privacy Policy will be communicated via email and credit account statements to all active customers.</p> 
          <h3>Right to Complain to the Information Commissioner's Office (ICO)</h3>
          <p>You have a right to lodge a complaint with the Information Commissioner's Office (ICO) if you have a complaint with how you believe your personal data has been handled. For more information, please visit <a href="https://ico.org.uk/concerns" target="_blank" rel="noreferrer">https://ico.org.uk/concerns</a></p>
        </div>
      </Layout>
    )
  }
  
  export default privacyPolicy